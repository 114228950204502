import React, { useEffect, useRef, useState } from "react";
import { Alert, Box, Button, IconButton, Typography } from "@mui/material";
import { ReactComponent as LogoIcon } from "../../assets/onyx.svg";
import LoginBody from "./LoginBody";
import SignButtons from "./SignButtons";
import SignInDialog from "./SignInDialog";
import { useNavigate } from "react-router-dom";
import { users } from "../../util/users";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { getAuth } from "../../api/api";

const LoginContent = (props) => {
  const { activeButton, setActiveButton } = props;
  const [open, setOpen] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const navigate = useNavigate();

  useEffect(() => {
    isLoggedIn && navigate("/dashboard");
  }, []);

  const handleLogin = async () => {
    const resp = await getAuth(username, password);
    if (typeof resp === "object") {
      sessionStorage.setItem("pro_id", resp.pro_id);
      navigate("/dashboard");
    } else {
      setShowError(true);
      setErrorMsg(resp);
    }
  };

  const ref = useRef(null);

  const handleClick = () => {
    setActiveButton("about");
    ref?.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {/* {showError &&
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowError(false);
              }}
            >
              <CloseOutlinedIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <Typography variant="h6">
            {errorMsg}
          </Typography>
        </Alert>} */}
      {open && (
        <SignInDialog
          open={open}
          setOpen={setOpen}
          setOpenSignUp={setOpenSignUp}
          handleLogin={handleLogin}
          username={username}
          password={password}
          setUsername={setUsername}
          setPassword={setPassword}
          errorMsg={errorMsg}
          error={showError}
          setShowError={setShowError}
          disabled={username == "" || password == ""}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pt: 5,
        }}
      >
        <Box sx={{ display: "flex", pl: 15, mt: 2 }}>
          {/* <LogoIcon /> */}
          {/* <Box
          component="img"
          sx={{
            height: 50,
            width: 200,
            // maxHeight: { xs: 100, md: 100 },
            // maxWidth: { xs: 100, md: 100 },
          }}
        alt="logo"
        src={process.env.REACT_APP_LOGO}
        /> */}
        </Box>
        <div style={{ display: "flex", mt: 0 }}>
          <Button
            variant={activeButton === "about" ? "contained" : "text"}
            sx={{
              borderRadius: 10,
              width: 150,
              backgroundColor: activeButton === "about" ? "#8d8dda" : "none",
            }}
            onClick={handleClick}
          >
            <Typography
              variant="h5"
              sx={{ display: "flex", color: "white", fontFamily: "Outfit" }}
            >
              About
            </Typography>
          </Button>
          <Button
            variant={activeButton === "contact" ? "contained" : "text"}
            sx={{
              borderRadius: 10,
              width: 150,
              backgroundColor: activeButton === "contact" ? "#8d8dda" : "none",
            }}
            onClick={() => setActiveButton("contact")}
          >
            <Typography
              variant="h5"
              sx={{ display: "flex", color: "white", fontFamily: "Outfit" }}
            >
              Contact
            </Typography>
          </Button>
          {/* <Button
            variant={activeButton === "signIn" ? "contained" : "text"}
            sx={{
              borderRadius: 10,
              width: 150,
              backgroundColor: activeButton === "signIn" ? "#8d8dda" : "none",
            }}
            onClick={() => {
              setActiveButton("signIn");
              setOpen(true);
            }}
          >
            <Typography
              variant="h5"
              sx={{ display: "flex", color: "white", fontFamily: "Outfit" }}
            >
              Sign In
            </Typography>
          </Button> */}
        </div>
      </Box>
      <LoginBody />
      <SignButtons setOpen={setOpen} setOpenSignUp={setOpenSignUp} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
          p: 5,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            mt: "15%",
            color: "white",
            fontFamily: "Outfit",
            letterSpacing: 7,
          }}
        >
          About
        </Typography>
        <Typography
          ref={ref}
          variant="h6"
          sx={{ mt: "5%", p: 2, color: "white", fontFamily: "Outfit" }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Typography>
      </Box>
    </>
  );
};

export default LoginContent;
